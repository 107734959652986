.modal-profile__data {
    margin-top: 10px;
    display: flex;
}
.modal-profile__data img {
    width: 150px;
    border-radius: 6px;
    margin-right: 15px;
}
.modal-profile__data-info {
    margin-top: 10px;
}
.modal-profile__data-info h4 {
    font-size: 18px;
    font-weight: bold;
}
.modal-profile__data-info em {
    font-size: 15px;
    color: #939393;
    display: block;
    margin-top: 4px;
}
.modal-profile__data-info a {
    display: block;
    margin: 5px 0 0 0;
    font-size: 14px;
    text-decoration: underline;
}
.modal-profile__exit {
    margin-top: 25px;
}
.modal-profile__color-item {
    background-color: #FFF4DE;
    height: 75px;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 1.25rem;
    box-sizing: border-box;
    cursor: pointer;
}
.modal-profile__color-item:hover {
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.modal-profile__color-item-text {
    margin-left: 15px;
}
.modal-profile__color-item i {
    font-size: 25px;
    color: #FFA800;
}
.modal-profile__color-item span {
    display: block;
    color: #3F4254;
    font-size: 14px;
    font-weight: bold;
}
.modal-profile__color-item em {
    color: #B5B5C3;
    font-size: 13px;
    display: block;
    margin-top: 2px;
}